import "./footerStyle.css"
export default function Footer()
{
    return(
        <div className="footer-container">
            <div className="contact-container">
                <a href="https://wa.me/559991238421">
                    <img src="images/whatsapp.png" className="contact-icon" alt="insta"/>
                </a>
                <a href="https://www.instagram.com/deliciasdavirginia/">
                    <img src="images/instagram.png" className="contact-icon" alt="mail"/>
                </a>
            </div>
            <div className="developed-by">© Laika </div>
        </div>
    )
}