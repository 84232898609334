export const CATEGORY_ID = {
    BOLO: "bolo",
    DOCE: "doce",
}
export const SESSION = {
    SESSION1: {
        bolo: "R$ 75,00/kg",
        doce: "R$ 160,00"
    },
    SESSION2: {
        bolo: "R$ 80,00/kg",
        doce: "R$ 200,00"
    },
    SESSION3: {
        bolo: "R$ 85,00/kg",
        doce: "R$ 250,00"
    },
    SESSION4: {
        bolo: "R$ 55,00/unidade",
        doce: "R$ 350,00"
    },
    SESSION5: {
        bolo: "R$ 65,00/unidade",
        doce: "R$ 4,50/unidade"
    },
    SESSION6: {
        bolo: "R$ 75,00/unidade",
        doce: "R$ 7,00/unidade"
    },
}


export const PRODUCTS = {
    [CATEGORY_ID.BOLO]: {
        [SESSION.SESSION1.bolo]: [
            {
                name: "Brigadeiro",
                image: "bolobrigadeiro",
                description: "Recheio clássico de brigadeiro feito com cacau e leite condensado."
            },
            {
                name: "Doce de Leite",
                image: "docedeleite",
                description: "Recheio super cremoso de doce de leite artesanal."
            },
            {
                name: "Cocada Cremosa",
                image: "cocadacremosa",
                description: "Doce feito com leite condensado e flocos de coco."
            },
            {
                name: "Mousse de Ninho",
                image: "moussedeninho",
                description: "Mousse de ninho com textura leve."
            },
        ],
        [SESSION.SESSION2.bolo]: [
            {
                name: "Cremoça",
                image: "cremoca",
                description: "Recheio cremoso a base de leite moça."
            },
            {
                name: "Morango",
                image: "bolomorango",
                description: "Bolo com pedaços de morango frescos, incorporados no Cremoça ou na Mousse de Ninho, conforme sua preferência."
            },
            {
                name: "Ameixa",
                image: "ameixa",
                description: "Recheio com ameixa seca incorporada no Cremoça ou na Mousse de Ninho, conforme sua preferência."
            },
            {
                name: "Cupuaçu",
                image: "bolocupu",
                description: "Recheio de geleia de cupuaçu, incorporada ao Cremoça ou à Mousse de Ninho, conforme escolha."
            },
            {
                name: "Delícia de Abacaxi",
                image: "deliciadeabacaxi",
                description: "Doce de abacaxi caseiro combinado com cremoça ou Mousse de Ninho, conforme sua preferência." 
            },
            {
                name: "BomBom",
                image: "bolobombom",
                description: "Recheio com pedaços de bombom Sonho de Valsa incorporado ao Cremoça ou à Mousse de Ninho, conforme sua preferência."
            },
            {
                name: "Mousse de Chocolate",
                image: "moussedechocolate",
                description: "Recheio leve e aerado de Mousse de Chocolate, com sabor intenso e textura suave."
            },
            {
                name: "Brigadeiro de Nutella",
                image: "brigadeirodenutella",
                description: "Recheio cremoso de brigadeiro combinado com Nutella pra um sabor rico e aveludado de chocolate com avelã."
            },
    
        ],
        [SESSION.SESSION3.bolo]: [
            {
                name: "Damasco",
                image: "bolodamasco",
                description: "Recheio com damasco seco, combinados ao Cremoça ou Mousse de Ninho, conforme preferência."
            },
            {
                name: "Castanha-do-Pará",
                image: "castanhadopara",
                description: "Recheio com Castanha-do-Pará triturada, incorporada ao Cremoça ou à Mousse de Ninho, conforme preferência."
            },
            {
                name: "Nozes",
                image: "bolonozes",
                description: "Recheio com nozes picadas, musturadas ao Cremoça ou à Mousse de Ninho, conforme preferência."
            },
            {
                name: "Frutas Vermelhas",
                image: "bolofrutasvermelhas",
                description: "Recheio de geleia de frutas vermelhas incorporado em cremoça ou mousse de ninho conforme a preferência."
            },
            {
                name: "Crocante de Castanha de Caju",
                image: "crocantecaju",
                description: "Recheio de castanha de caju caramelizada incorporada ao Cremoça."
            },
        ],
        [SESSION.SESSION4.bolo]: [
            {
                name: "Bolo vulcão Chocolate",
                image: "vulcaoChocolate",
                realImage: true,
                description: "Bolo vulcão com massa de chocolate e cobertura de brigadeiro."
            },
            {
                name: "Bolo vulcão Limão",
                image: "bololimao",
                realImage: true,
                description: "Bolo vulcão com massa e cobertura de limão."
            },
            {
                name: "Bolo vulcão Milho com Goiabada",
                image: "vulcaoGoiabada",
                realImage: true,
                description: "Bolo vulcão com massa de milho e cobertura de goiabada."
            },
            {
                name: "Bolo vulcão Cenoura",
                image: "vulcaoChocolate",
                realImage: true,
                description: "Bolo vulcão com massa de cenoura e cobertura de chocolate."
            },
        ],
        [SESSION.SESSION5.bolo]: [
            {
                name: "Bolo vulcão Ninho",
                image: "vulcaoNinho",
                realImage: true,
                description: "Bolo vulcão com massa e cobertura de ninho."
            },
            {
                name: "Bolo vulcão Dois Amores",
                image: "doisAmores",
                realImage: true,
                description: "Bolo vulcão com massa de ninho ou chocolate, e cobertura mista de ninho e chocolate."
            },
        ],
        [SESSION.SESSION6.bolo]: [
            {
                name: "Bolo vulcão Ninho com Nutella",
                image: "vulcaoNinhoNutella",
                realImage: true,
                description: "Bolo formigueiro com massa e cobertura de ninho, finalizado com Nutella."
            },
            {
                name: "Bolo vulcão Trufado de Cupuaçu",
                image: "vulcaoCupu",
                realImage: true,
                description: "Bolo formigueiro com massa de chocolate, trufado com geleia de cupuaçu e com cobertura de chocolate. "
            }
        ]
    },
    [CATEGORY_ID.DOCE]: {
        [SESSION.SESSION1.doce]: [
            {
                name: "Brigadeiro",
                image: "brigadeiro",
                description: "Docinho clássico feito à base de leite condensado e cacau, enrolado e coberto com granulado."
            },
            {
                name: "Beijinho",
                image: "beijinho",
                description: "Delicado docinho de leite condensado e coco ralado, enrolado e coberto com coco."
            },
            {
                name: "Ninho",
                image: "ninho",
                description: "Doce feito com leite ninho e leite condensado, de sabor suave e cremoso."
            },
            {
                name: "Casadinho",
                image: "casadinho",
                description: "Combinação perfeita entre brigadeiro de chocolate e brigadeiro branco, unidos e agradando todos os paladares."
            },
            {
                name: "Amendoim",
                image: "amendoim",
                description: "Doce crocante feito com leite condensado e amendoim, trazendo crocancia e cremosidade."
            }
        ],
        [SESSION.SESSION2.doce]: [
            {
                name: "Ninho com Nutella",
                image: "ninhocomnutella",
                description: "A cremosidade do brigadeiro de Ninho com um recheio generoso de Nutella."
            },
            {
                name: "Churros",
                image: "churros",
                description: "Brigadeiro de doce de leite com uma pitada de canela, enrolado em açucar e canela, lembrando o famoso churros em formato de docinho."
            },
            {
                name: "Olho de Sogra",
                image: "olhodesogra",
                description: "Combina o sabor do beijinho de coco com ameixa seca, enrolado em açúcar."
            },
        ],
        [SESSION.SESSION3.doce]: [
            {
                name: "Romeu e Julieta",
                image: "romeuejulieta",
                description: "Brigadeiro de queijo com goiabada, remetendo ao tradicional prato, em versão de docinho."
            },
            {
                name: "Brûlée",
                image: "brulee",
                description: "Uma versão inspirada no famoso creme brûlée francês, com uma casquinha crocante de açúcar queimado e interior cremoso e suave."
            },
            {
                name: "Surpresa de Uva",
                image: "surpresadeuva",
                description: "Docinho cremoso de leite condensado envolvendo uma uva fresca, enrolado em açúcar."
            },

        ],
        [SESSION.SESSION4.doce]: [
            {
                name: "Caixa de Morango",
                image: "caixademorango",
                description: "Caixa feita de chocolate recheada com cremoça e morango."
            },
            {
                name: "Caixa de Cereja",
                image: "caixacereja",
                description: "Caixa feita de chocolate recheada com cremoça e cereja."
            },
            {
                name: "Caixa de Frutas Vermelhas",
                image: "caixageleia",
                description: "Caixa feita de chocolate recheada com cremoça e geleia de frutas vermelhas."
            },
        ],
        [SESSION.SESSION5.doce]: [
            {
                name: "Bombom de Uva",
                image: "bombomuva",
                description: "Uva fresca envolta em Cremoça e coberta com chocolate."
            },
            {
                name: "Bombom de Nozes",
                image: "bombomnozes",
                description: "Nozes trituradas incorporadas ao Cremoça e cobertas com chocolate."
            },
            {
                name: "Bombom de Castanha",
                image: "bombomcastanha",
                description: "Castanhas-do-Pará trituradas incorporadas ao Cremoça e cobertas com chocolate"
            },
        ],
        [SESSION.SESSION6.doce]: [
            {
                name: "Bombom de Morango",
                image: "bombommorango",
                description: "Morangos frescos envolvidos em Cremoça e cobertos com chocolate."
            },
            {
                name: "Bombom de Cupuaçu",
                image: "bombomcupu",
                description: "Geleia de cupuaçu envolta em Cremoça  e coberta com chocolate."
            },
            {
                name: "Bem casado",
                image: "bemcasado",
                description: "Tradicional doce de massa leve e recheio de doce de leite. Símbolo de uma união e celebração perfeita para festas epeciais."
            },
        ]
    }    
}
