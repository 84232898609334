import './productStyle.css'

export default function ProductCard({item})
{
    return (
        <div className="card-container">
            <div className="image-container">
                <img className="image-container" src={`images/${item.image}.webp`} alt={item.image}/>
                {item.realImage !== true && <span className='ilustrative-image-info'>Imagem ilustrativa</span>}
                
            </div>
            <div className="card-information-container">
                <div className="product-name">{item.name}</div>
                <div>
                    {item.description}
                </div>
            </div>
        </div>
    )
}