import './categoryButtonStyle.css'
import { CATEGORY_ID } from '../../constants/products'

export default function CategoryButton({selectedCategory, onClickCategory})
{
    const selectedStyle = {
        backgroundColor: "#F48CA0",
        color: "white",
    }

    return (
        <div className="category-button-container">
            <div 
                className='category-option'
                onClick={() => onClickCategory(CATEGORY_ID.BOLO)}
                style={selectedCategory === CATEGORY_ID.BOLO ? selectedStyle : {}}
            >
                Bolos
            </div>
            <div 
                className='category-option'
                onClick={() => onClickCategory(CATEGORY_ID.DOCE)}
                style={selectedCategory === CATEGORY_ID.DOCE ? selectedStyle : {}}
            >
                Doces
            </div>
        </div>
    )
}