import './priceLabelStyle.css'

export default function PriceLabel({price})
{
    return (
        <div className="price-label-container">
            <div className='circle'></div>
            <span>{price}</span>
        </div>
    )
}