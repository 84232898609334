import './App.css';
import Feed from './components/feed/Feed';
import Footer from './components/footer/Footer';
import TopBar from './components/topbar/TopBar';

function App() {
  return (
    <div className="app-container">
      <TopBar/>
      <Feed/>
      <Footer/>
    </div>
  );
}

export default App;
