
import { useState } from "react";
import { CATEGORY_ID, PRODUCTS } from "../../constants/products";
import CategoryButton from "../categoryButton/CategoryButton";
import PriceLabel from "../priceLabel/PriceLabel";
import ProductCard from "../productCard/ProductCard";
import "./feedStyle.css"
export default function Feed()
{
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_ID.BOLO)

    const info = {
        [CATEGORY_ID.BOLO]: "Bolos vendidos no quilo, a partir de 1,5 kg. Opções de massa branca ou chocolate e até dois tipos recheios por bolo. Os scraps são terceirizados e têm um custo adicional.",
        [CATEGORY_ID.DOCE]: "Os doces são vendidos a partir de 100 unidades, e o cento pode ser dividido em até dois sabores diferentes."
    }

    return (
        <div className="feed-container">
            <div className="switch-container">
                <CategoryButton
                    selectedCategory={selectedCategory}
                    onClickCategory={(category)=> setSelectedCategory(category)}
                />
            </div>
            <div className="products-container">
            <div className="products-info">
                {info[selectedCategory]}
            </div>
                {Object.entries(PRODUCTS[selectedCategory]).map(([sessionName, items]) => {
                    return(   
                        <div key={sessionName} className="session-container">
                            <PriceLabel price={sessionName}/>
                            {items.map(item => (
                                <ProductCard 
                                    key={item.name}
                                    item={item}
                                />
                            ))}
                        </div>
                    )
                })}
            </div>
        </div> 
    )
}